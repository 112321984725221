import { ELEMENTS } from 'providers/contentProvider/Elements';

export interface ProgressBarProps {
  activeStep: number;
  totalSteps: number;
}

export const getElement = (flowStep: number) => {
  switch (flowStep) {
    case 1: return ELEMENTS.FLOW_STEP_ONE;
    case 2: return ELEMENTS.FLOW_STEP_TWO;
    case 3: return ELEMENTS.FLOW_STEP_THREE;
    default: throw new Error('Invalid flow step');
  }
};
