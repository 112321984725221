import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import Cookies from 'js-cookie';
import qs, { ParsedQs } from 'qs';
import { get } from 'lodash';
import { Main as GenericMain } from 'components/generic';
import { useRecaptcha } from 'hooks/useRecaptcha/useRecaptcha';
import FeatureList from 'components/FeatureList/FeatureList';
import { Main } from 'common/main/Main';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import { useBeginCheckoutEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { useSendEventToSegment } from 'hooks/useSendEventToSegment/useSendEventToSegment';
import { generateSha256Hex } from 'utils/HashGenerator';
import { NewFeatureContainer } from 'components/newFeatureContainer/NewFeatureContainer';
import { EmailInputForm } from 'components/emailInputForm/EmailInputForm';
import { AccountDetailsForm } from 'components/accountDetailsForm/AccountDetailsForm';
import { useExistingUserCheck } from 'hooks/useExistingUserCheck/useExistingUserCheck';
import { useContentViewed } from 'hooks/Analytics/useAnalytics';
import {
  ANALYTICS_ID,
  COOKIE_AJS_ANONYMOUS_ID,
  COOKIE_AJS_USER_ID,
  COOKIE_MYOB_VISITOR_ID,
  EXCLUDED_PRODUCTS,
  FEELIX_THEMES,
  GTM_ANALYTICS_CONSTANTS,
  PRICING_LIST_URL,
  PROMOTION_CODE,
  REGIONS,
  SUBSCRIPTION_TYPE,
  SUPPORTED_ANNUAL_PRICE_PRODUCTS
} from '../../constants';

export const getPageHeaderTitle = (
  type: SUBSCRIPTION_TYPE,
  productFreePeriod: number,
  trialDealDuration: number
): string => {
  if (type === SUBSCRIPTION_TYPE.TRIAL) {
    return trialDealDuration === 1 ?
      'Start your FREE 30-day trial!' :
      `Start your FREE ${trialDealDuration}-month trial!`;
  }
  return productFreePeriod > 0 ? `Try FREE for ${productFreePeriod} days!` : 'Start your subscription!';
};

export const getSubmitButtonTextOnEmailInputForm = (type: SUBSCRIPTION_TYPE): string =>
  (type === SUBSCRIPTION_TYPE.TRIAL ? 'Start your trial' : 'Continue');

export const getSubmitButtonTextOnAccountDetailsForm = (
  type: SUBSCRIPTION_TYPE,
  isNewUser: boolean,
  isSolo: boolean
): string => {
  if (isNewUser) {
    if (isSolo) {
      return 'Step 2: Create password';
    }
    return 'Next: Set my password';
  }
  return type === SUBSCRIPTION_TYPE.TRIAL ?
    'Sign in to create a new trial' : 'Sign in to create subscription';
};

const isAnnualPricingSupported = (region: string, productId: string): boolean => {
  const supportedAnnualPricingProducts = get(SUPPORTED_ANNUAL_PRICE_PRODUCTS, region);
  return supportedAnnualPricingProducts?.includes(productId);
};

const isProductDeprecated = (region: string, productId: string): boolean => {
  const excludedProductsByRegion = get(EXCLUDED_PRODUCTS, region);
  return excludedProductsByRegion && Object.values(excludedProductsByRegion).includes(productId);
};

export const EmailPage: React.FC = () => {
  const { search } = useLocation();
  const [emailValue, setEmailValue] = useState('');
  const {
    productId,
    region,
    subscriptionType,
    productFreePeriod,
    setHasPurchaseSubmitted,
    isAnnualRoute,
    theme,
    setEmailChecked
  } = useSessionContext();
  setHasPurchaseSubmitted(false);
  const { isLoading: isProductLoading, isSuccess: fetchProductDataSuccess, productData } = useGetProduct();
  const {
    isNewUser, isEmailChecked, isError, identityId
  } = useExistingUserCheck(emailValue);
  trackCheckoutStarting(isEmailChecked, emailValue); // This function contains React Hooks, never put it in a condition.
  const {
    isLoading: isRecaptchaLoading,
    success: isRecaptchaSuccess
  } = useRecaptcha({ enabled: isEmailChecked, action: 'view', identityId });
  const isLoading = isRecaptchaLoading || isProductLoading;
  const useGeneric = subscriptionType !== SUBSCRIPTION_TYPE.TRIAL;
  if (isLoading) {
    return (<div data-testid='email-page-loading'/>);
  }

  if (isProductDeprecated(region, productId)) {
    const redirectUrl = region === REGIONS.AUSTRALIA ? PRICING_LIST_URL.AU : PRICING_LIST_URL.NZ;
    window.location.replace(redirectUrl);
    return null;
  }

  setEmailChecked && setEmailChecked(isEmailChecked);

  const isProductAndRegionMatched: boolean = fetchProductDataSuccess && productData && region === productData.region;
  const rejectAnnualPrice: boolean = isAnnualRoute && !isAnnualPricingSupported(region, productId);

  if (
    isRecaptchaSuccess === false ||
    !isProductAndRegionMatched ||
    !validateWestpac(search) ||
    rejectAnnualPrice
  ) {
    return <Redirect to='/error' />;
  }

  const renderEmailInputForm = () => {
    const trialDealDuration = productData?.items[0]?.trialDeal?.duration ?? 1;
    const isSolo = theme === FEELIX_THEMES.SOLO;
    if (isEmailChecked) {
      return (
        <AccountDetailsForm
          pageHeaderText={getPageHeaderTitle(subscriptionType, productFreePeriod, trialDealDuration)}
          submitButtonText={getSubmitButtonTextOnAccountDetailsForm(subscriptionType, isNewUser, isSolo)}
          isNewUser={isNewUser}
          emailValue={emailValue}
        />
      );
    }
    return (
      <EmailInputForm
        handleEmailChecked={setEmailValue}
        pagerHeaderTitle={getPageHeaderTitle(subscriptionType, productFreePeriod, trialDealDuration)}
        submitButtonText={getSubmitButtonTextOnEmailInputForm(subscriptionType)}
        isError={isError}
      />
    );
  };

  if (theme === FEELIX_THEMES.SOLO || useGeneric) {
    return (
      <GenericMain>
        {renderEmailInputForm()}
      </GenericMain>
    );
  }

  return (
    <Main>
      <NewFeatureContainer>
        <FeatureList />
      </NewFeatureContainer>
      {renderEmailInputForm()}
    </Main>
  );
};

const validateWestpac = (search: string) => {
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true, decoder: (c) => c }) as ParsedQs;
  if (searchParams.pc === PROMOTION_CODE.CODE_WESTPACBUSINESSACC_12MTH_FREE) {
    return validateParamsExist(searchParams) && validateCustomerIdIfNeeded(searchParams) && validateHash(searchParams);
  }
  return true;
};

const buildDataToHash = (pcustid: string, timestamp: string) =>
  `pcustid=${pcustid}&timestamp=${timestamp}&partnerid=MYOB_01`;

const validateHash = (searchParams: ParsedQs) => {
  const { pcustid, timestamp, offer } = searchParams;
  const data = buildDataToHash(pcustid as string, timestamp as string);
  const sha256Hex = generateSha256Hex(data);
  return sha256Hex.toLowerCase() === offer?.toString().toLowerCase();
};

const validateParamsExist = (searchParams: ParsedQs) => searchParams.pcustid &&
    searchParams.timestamp && searchParams.offer;

const validateCustomerIdIfNeeded = (searchParams: ParsedQs) =>
  searchParams.pcustid && /^[0-9a-zA-Z]{1,50}$/.test(searchParams.pcustid.toString());

export const trackCheckoutStarting = (isEmailChecked: boolean, emailValue: string): void => {
  /* eslint-disable react-hooks/rules-of-hooks */
  useContentViewed({
    elementId: ANALYTICS_ID.CONTENT_VIEWED_TRIAL_FORM,
    enabled: isEmailChecked
  });

  const myobVisitorId = Cookies.get(COOKIE_MYOB_VISITOR_ID);
  const anonymousId = Cookies.get(COOKIE_AJS_ANONYMOUS_ID);
  const userId = Cookies.get(COOKIE_AJS_USER_ID);
  const identifyParams = {
    userId,
    anonymousId,
    traits: {
      email: emailValue,
      myobVisitorId
    }
  };

  const trackParams = {
    event: GTM_ANALYTICS_CONSTANTS.BEGIN_CHECKOUT_SEGMENT,
    userId,
    anonymousId,
    properties: {
      myobVisitorId
    }
  };
  useBeginCheckoutEvent({ email: emailValue }, isEmailChecked, GTM_ANALYTICS_CONSTANTS.BEGIN_CHECKOUT_SEGMENT);

  useSendEventToSegment({ shouldSendSegmentEvent: isEmailChecked, identifyParams, trackParams });
  /* eslint-enable react-hooks/rules-of-hooks */
};
