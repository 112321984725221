import React from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useLocation } from 'react-router-dom';
import { SoloConfirmation } from 'components/generic';
import { getSoloAppLinks, MOBILE_APP_CONFIGURED_PRODUCTS } from '../../constants';

const CONFIGURED_PRODUCTS = Object.values(MOBILE_APP_CONFIGURED_PRODUCTS);
export const MobileAppStore: React.FC = () => {
  const { region } = useSessionContext();
  const location = useLocation();
  const product = (location.pathname.split('/')[2]) as string;

  const appLinks = getSoloAppLinks();
  if (!CONFIGURED_PRODUCTS.includes(product)) {
    window.location.replace(`https://myob.com/${region.toLowerCase()}/pricing`);
    return null;
  }
  if (isIOS || isAndroid) {
    const url = isIOS ? appLinks.APPLE_APP_STORE : appLinks.GOOGLE_PLAY_STORE;
    window.location.replace(url);
  }

  return <SoloConfirmation />;
};
