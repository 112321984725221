import React from 'react';
import {
  Box,
  Input,
  Heading,
  Text
} from '@myob/myob-widgets';
import { Button } from 'components/button/Button';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import soloBackgroundImage from 'assets/images/solo_mobile_landing_hero.png';
import { Image } from 'components/image/Image';
import { SubscriptionHeading } from 'components/generic/subscriptionHeading/SubscriptionHeading';
import { usePromoBoxFeatureContext } from '../../featureToggle/PromoCodeBoxFeatureToggle';
import { PromoCodeBox } from '../../promoCodeBox/PromoCodeBox';
import {
  ANALYTICS_ID, COLOURS_AS_HEX, FEELIX_THEMES, REGULAR_EXPRESSIONS
} from '../../../constants';
import defaultStyle from './EmailForm.module.scss';
import soloStyle from './EmailForm.solo.module.scss';

type EmailFormProps = {
  handleSubmit: () => void;
  submitDisabled: boolean;
  formRegister: (data: any) => void;
  isError: boolean;
  errors: any;
}

export const EmailForm: React.FC<EmailFormProps> = (
  {
    handleSubmit,
    submitDisabled,
    formRegister,
    isError,
    errors
  }
) => {
  const { phoneNumber } = useSessionContext();
  const { getLabels } = useContentContext();
  const isPromoBoxFeatureEnabled = usePromoBoxFeatureContext();
  const { theme } = useSessionContext();
  const isSoloTheme = theme === FEELIX_THEMES.SOLO;
  const style = isSoloTheme ? soloStyle : defaultStyle;
  const headingOne = getLabels(ELEMENTS.EMAIL_PAGE_HEADING_ONE);
  const headingTwo = getLabels(ELEMENTS.EMAIL_PAGE_HEADING_TWO);

  return (
    <Box className={style.container} data-testid='email-form'>
      {isSoloTheme && <Image
        alt='background'
        src={soloBackgroundImage}
        className={style.backgroundImage}
      />}
      <SubscriptionHeading theme={theme}/>
      {headingOne && (
        <Heading level={1} marginBottom='xs'>
          {headingOne}
        </Heading>
      )}
      {headingTwo && (
        <Text fontSize='sm' fontWeight='inherit' marginBottom='lg'>
          {headingTwo}
        </Text>
      )}
      <Input
        data-testid='email-input'
        reference={formRegister({ required: true, pattern: REGULAR_EXPRESSIONS.EMAIL })}
        name='email'
        label='Email*'
        containerClassName={style.inputContainer}
        className={style.input}
        errorMessage={
                  !!errors.email && (errors.email.type === 'required' ? 'Email is required' : 'Email is invalid')}
      />
      {isError && (
        <p style={{ marginTop: '1rem', color: COLOURS_AS_HEX.Berry }}>
          Something went wrong. If the issue persists, contact MYOB support on{' '}
          {phoneNumber.salesPhoneNumber}
        </p>
      )}
      {isPromoBoxFeatureEnabled && <PromoCodeBox/>}
      <Button
        id={ANALYTICS_ID.BUTTON_EXISTING_USER_CHECK}
        className={!isSoloTheme && defaultStyle.submitButton}
        data-testid='email-submit-button'
        onClick={handleSubmit}
        disabled={submitDisabled}
        marginTop='lg'
      >
        {getLabels(ELEMENTS.EMAIL_SUBMIT_BUTTON)}
      </Button>
    </Box>
  );
};
