import React from 'react';
import { Box } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { ProductCard } from 'components/generic/productCard/ProductCard';
import { BackgroundImage } from '../background/BackgroundImage';
import style from './InfoPanel.module.scss';
import { FEELIX_THEMES } from '../../../constants';

const Spacer: React.FC = () => (
  <Box className={style.spacer} />
);

type InfoPanelProps = {
  contentPanelFaded: boolean;
  setContentPanelFaded: (fade: boolean) => void;
  showProductCard?: boolean;
};

export const InfoPanel: React.FC<InfoPanelProps> = (
  {
    contentPanelFaded,
    setContentPanelFaded,
    showProductCard = true
  }
) => {
  const { theme } = useSessionContext();
  return (
    <Box className={[style.infoPanel, { [style.default]: theme !== FEELIX_THEMES.SOLO }]}>
      <BackgroundImage theme={theme} />
      <Spacer/>
      {showProductCard &&
      <ProductCard
        theme={theme}
        isFooterOpen={contentPanelFaded}
        onFooterOpen={setContentPanelFaded}
      />}
    </Box>
  );
};
