import React from 'react';
import {
  Box, Heading, Text, Spinner
} from '@myob/myob-widgets';
import laptopImage from 'assets/images/computer-screen.png';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import ProgressButton from 'components/confirmation/progressButton/ProgressButton';
import style from './DefaultConfirmation.module.scss';
import { ConfirmationProps } from '../types';
import config from '../../../../Config';
import { ANALYTICS_ID, SUBSCRIPTION_TYPE } from '../../../../constants';

const getDescriptionContent = (isAnnualPrice: boolean) => (isAnnualPrice ? {
  header: 'The first payment may be invoiced on a pro rata basis depending on when you sign up. ' +
    'Payments will be debited from your nominated bank account or credit card.',
  body: 'Your subscription will auto-renew until it is cancelled. ' +
    'You can cancel by visiting the \'Manage My Product\' section of \'My Account\'.',
  footer: 'For further billing or account support, visit ',
  link: 'https://www.myob.com/au/support'
} : {
  header: 'The first payment may be invoiced on a pro rata basis depending on the day of the month you sign up.',
  body: 'MYOB issues invoices on the 1st of each month and also offers 7 day payment terms, ' +
    'this means that you\'ll receive your invoice on the 1st, and any payment will be debited ' +
    'from your nominated bank account or credit card 7 days later.',
  footer: 'Your subscription auto-renews until it is cancelled. You can cancel at any ' +
    'time within the \'Manage My Product\' section of \'My Account\'.'
});

const trialContent = (
  <Box className={style.trialContent}>
    <img className='confirmation-page-main-image' src={laptopImage} alt='desktop'/>
    <div className='confirmation-page-main-image-desc'>
      <p><b>Access your product</b></p>
      <p>Learn the ropes with the help of your own personalised setup activities</p>
    </div>
  </Box>
);

const getContent = (isAnnualPrice: boolean, subscriptionType: SUBSCRIPTION_TYPE) => {
  if (subscriptionType === SUBSCRIPTION_TYPE.NEW_TRIAL) {
    return trialContent;
  }
  const description = getDescriptionContent(isAnnualPrice);
  return (
    <Box className={style.subscribeContent}>
      <Text fontWeight='inherit'>{description.header}</Text>
      <Text fontWeight='inherit'>{description.body}</Text>
      <Text fontWeight='inherit'>
        {description.footer}
        {description.link && <a href={description.link} target='_blank' rel='noopener noreferrer'>
          {description.link}</a>}
      </Text>
    </Box>
  );
};

export const DefaultConfirmation: React.FC<ConfirmationProps> =
  ({
    isLoading
  }) => {
    const { getLabels } = useContentContext();
    const {
      isAnnualPrice,
      subscriptionType
    } = useSessionContext();
    const pageTitle = getLabels(ELEMENTS.CONFIRMATION_PAGE_TITLE);
    return (
      <Box className={style.container}>
        <Heading level={1} className={style.heading}>
          {pageTitle}
        </Heading>
        <Box className={style.content}>
          {isLoading && <Spinner size='small' data-testid='spinner'/>}
          {!isLoading && (
          <>
            {getContent(isAnnualPrice, subscriptionType)}
            <ProgressButton
              id={ANALYTICS_ID.BUTTON_ACCESS_SOFTWARE}
              startText='Setting up your software'
              endText='Access your software now'
              duration={config.DEEP_LINKING.BUTTON_DISABLE_TIME}
              href={config.PRODUCT_URL}
            />
          </>
        )}
        </Box>
      </Box>
    );
  };
