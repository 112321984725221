import { useState, useEffect } from 'react';
import { useGetProductData } from 'hooks/useGetProductData';
import { trackClickEvent } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { GTM4_ANALYTICS_CONSTANTS, PROMO_CODE_STATE } from '../../constants';

type UsePromoCodeVerifierType = {
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  state: string;
  updatePromoCodeBoxState: (inputPromoCode: string) => void;
};

const usePromoCodeBoxState = (): UsePromoCodeVerifierType => {
  const {
    productId, isAnnualRoute, setIsAnnualPrice, promoCode, setPromoCode
  } = useSessionContext();
  const [inputPromoCode, setInputPromoCode] = useState('');
  const [promoCodeState, setPromoCodeState] = useState(promoCode ? PROMO_CODE_STATE.VALID : PROMO_CODE_STATE.EMPTY);

  const {
    productData, isLoading, isSuccess, isError
  } = useGetProductData(
    productId,
    isAnnualRoute,
    inputPromoCode
  );

  useEffect(() => {
    if (inputPromoCode && !isLoading) {
      if (isSuccess && productData?.items[0]?.deal.promoCode === inputPromoCode &&
          productData?.items[0]?.deal?.isPromoCodeBoxApplicable) {
        setPromoCode(inputPromoCode);
        setIsAnnualPrice(productData?.items[0]?.deal?.recurringInterval === 'ANNUAL');
        setPromoCodeState(PROMO_CODE_STATE.VALID);
        trackClickEvent(GTM4_ANALYTICS_CONSTANTS.VALID_PROMO_CODE_APPLIED, { promo_code: inputPromoCode });
      } else {
        setPromoCodeState(PROMO_CODE_STATE.INVALID);
        trackClickEvent(GTM4_ANALYTICS_CONSTANTS.INVALID_PROMO_CODE_APPLIED, { promo_code: inputPromoCode });
      }
    }
  }, [isLoading, isSuccess, inputPromoCode]);

  const updatePromoCodeBoxState = (inputPromoCode: string) => {
    if (!inputPromoCode || inputPromoCode.trim() === '') {
      return;
    }
    setInputPromoCode(inputPromoCode);
  };

  return {
    isSuccess,
    isError,
    isLoading,
    state: promoCodeState,
    updatePromoCodeBoxState
  };
};

export default usePromoCodeBoxState;
