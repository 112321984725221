import React from 'react';
import { Box, ProgressBar as FeelixProgressBar, Heading } from '@myob/myob-widgets';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import style from './ProgressBar.solo.module.scss';
import { ProgressBarProps, getElement } from '../types';

export const SoloProgressBar: React.FC<ProgressBarProps> =
  ({
    activeStep,
    totalSteps
  }) => {
    const { getLabels } = useContentContext();
    return (
      <Box
        className={style.progressBarContainer}
      >
        <FeelixProgressBar
          tone='brand'
          size='sm'
          className={style.progressBar}
          isAnimating={{
          from: activeStep,
          to: totalSteps
          }}
        />
        <Heading
          level={6}
          marginBottom='4xs'
        >
          Step {activeStep} of {totalSteps}
        </Heading>
        <Heading level={2}>
          {getLabels(getElement(activeStep))}
        </Heading>
      </Box>
    );
  };
