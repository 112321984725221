import React from 'react';
import {
  Box, Text, Heading
} from '@myob/myob-widgets';
import { Image } from 'components/image/Image';
import ArrowIcon from 'assets/svg/chevron_up_icon.svg';
import style from './ProductHeading.module.scss';
import modernStyle from './ProductHeading.modern.module.scss';
import { getStyleProps } from './ProductHeading.style';
import { FEELIX_THEMES } from '../../../../constants';

type ProductHeadingProps = {
  heading: string;
  isFooterOpen: boolean;
  theme: string;
}
export const PRODUCT_CARD_HEADING = 'Summary of your plan';
export const ProductHeading = (
  {
    heading,
    isFooterOpen,
    theme
  }: ProductHeadingProps
) => {
  const styleProps = getStyleProps(theme);
  return (
    <Box
      {...styleProps.container}
      className={[
        {
          [modernStyle.heading]: theme === FEELIX_THEMES.MODERN
        }
      ]}
    >
      <Image
        className={isFooterOpen ? style.chevronOpen : style.chevron}
        src={ArrowIcon}
        alt='arrow'
      />
      <Text
        className={[
          style.rowOne,
          { [modernStyle.rowOne]: theme === FEELIX_THEMES.MODERN }
        ]}
        data-content-desktop={PRODUCT_CARD_HEADING}
        data-content-mobile={heading}
        {...styleProps.rowOne}
      />
      <Heading
        className={[style.rowTwo, {
          [modernStyle.rowTwo]: theme === FEELIX_THEMES.MODERN
        }]}
        data-content-desktop={heading}
        data-content-mobile={PRODUCT_CARD_HEADING}
        {...styleProps.rowTwo}
      />
    </Box>
  );
};
