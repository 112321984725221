import React from 'react';
import { Button as FeelixButton } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { FEELIX_THEMES } from '../../constants';
import style from './Button.module.scss';

export const Button: React.FC<React.ComponentProps<typeof FeelixButton>> = (
  { children, ...props }
) => {
  const { theme } = useSessionContext();
  const isSoloTheme = theme === FEELIX_THEMES.SOLO;
  const buttonClassName = props.className || (isSoloTheme ? style.soloButton : style.defaultButton);

  return (
    <FeelixButton
      {...props}
      {...isSoloTheme ? { tone: 'brand' } : {}}
      className={buttonClassName}
    >
      {children}
    </FeelixButton>
  );
};
