import React from 'react';
import './NewFeatureContainer.scss';

export const NewFeatureContainer: React.FC = ({
  children
}) => (
  <>
    <div
      data-testid='trial-feature-box'
      className='trial-feature-box'
    >
      {children}
    </div>
  </>
);
