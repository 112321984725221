import React from 'react';
import soloImage from 'assets/svg/solo-logo.svg';
import { Image } from 'components/image/Image';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { FEELIX_THEMES } from '../../constants';
import style from './logo.module.scss';

export const SoloLogo: React.FC = () => {
  const { flowStep, theme } = useSessionContext();
  if (theme !== FEELIX_THEMES.SOLO) {
    return null;
  }

  return (
    <Image
      alt='solo'
      src={soloImage}
      // hide on first page when mobile view
      className={`${style.soloLogo} ${flowStep === -1 && style.hideLogo}`}
    />
  );
};
