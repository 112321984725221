import React, { useEffect, useState } from 'react';
import './directDebitForm.scss';
import { useForm } from 'react-hook-form';
import { Button } from 'components/button/Button';
import { PAYMENT_TERMS_ERROR_MESSAGE, REGIONS } from '../../../constants';

import { useSessionContext } from '../../../contexts/sessionProvider/SessionProvider';
import { DirectDebitDetail } from '../types';
import { DirectDebitContainerNZ } from './directDebitContainerNZ';
import { DirectDebitContainerAU } from './directDebitContainerAU';
import PaymentTerms from '../../paymentTerms/PaymentTerms';
import { bsbValidatorAU } from '../../../helpers/bsbValidation/bsbValidatorAU';

export const DirectDebitForm: React.FC<{
  isPaymentProfileCreationLoading: boolean,
  setDirectDebitDetail: React.Dispatch<React.SetStateAction<DirectDebitDetail>>,
  createPaymentProfile: () => void
  promoCode: string | undefined}> =
    ({
      isPaymentProfileCreationLoading,
      setDirectDebitDetail,
      createPaymentProfile,
      promoCode
    }) => {
      const { region } = useSessionContext();

      const [isDebitTermsCheck, setIsDebitTermsCheck] = React.useState(false);

      const [debitTermsErrorMessage, setDebitTermsErrorMessage] = React.useState('');

      const {
        register,
        errors,
        getValues,
        handleSubmit
      } = useForm<DirectDebitDetail>({ mode: 'onSubmit' });

      useEffect(() => {
        if (region === REGIONS.AUSTRALIA && getValues().bsb && getValues().accountName && getValues().accountNumber) {
          setDirectDebitDetail(getValues);
        }
        if (region === REGIONS.NEW_ZEALAND && getValues().bank && getValues().branch &&
            getValues().account && getValues().suffix && getValues().accountName) {
          setDirectDebitDetail(getValues);
        }
      }, [getValues().bsb, getValues().accountName, getValues().accountNumber, getValues().bank,
        getValues().branch, getValues().account, getValues().suffix]);

      const setDebitTerms = () => {
        setIsDebitTermsCheck(!isDebitTermsCheck);
        setDebitTermsErrorMessage('');
      };

      const postDirectDebitEvent = () => {
        if (!isDebitTermsCheck) {
          setDebitTermsErrorMessage(PAYMENT_TERMS_ERROR_MESSAGE);
        } else {
          handleSubmit(async () => createPaymentProfile())();
        }
      };

      const [isValidateBankCodeLoading, setIsValidateBankCodeLoading] = useState(false);
      const [isValidateBankCodeError, setIsValidateBankCodeError] = useState(false);

      const validateBankCode = async (bankCode: string): Promise<boolean> => {
        let isValid = false;

        try {
          setIsValidateBankCodeLoading(true);
          isValid = await bsbValidatorAU(bankCode);
        } catch (error) {
          setIsValidateBankCodeError(true);
          isValid = false;
        } finally {
          setIsValidateBankCodeLoading(false);
        }

        return isValid;
      };

      const disabled = isPaymentProfileCreationLoading || isValidateBankCodeLoading;

      return (
        <div style={{
            width: '21em', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'
        }}
        >
          {region === REGIONS.AUSTRALIA &&
            <DirectDebitContainerAU register={register} errors={errors} validateBankCode={validateBankCode}
              promoCode={promoCode}
              isValidateBankCodeError={isValidateBankCodeError}
              isValidateBankCodeLoading={isValidateBankCodeLoading}
            />}
          {region === REGIONS.NEW_ZEALAND &&
            <DirectDebitContainerNZ register={register} errors={errors}/>}
          <PaymentTerms
            isChecked={isDebitTermsCheck}
            errorMessage={debitTermsErrorMessage} setTerms={setDebitTerms}
          />
          {region === REGIONS.AUSTRALIA && <Button
            onClick={postDirectDebitEvent}
            disabled={disabled}
          >
            Next
            </Button>}
          {region === REGIONS.NEW_ZEALAND && <Button
            onClick={postDirectDebitEvent}
            disabled={isPaymentProfileCreationLoading}
          >
            Next
            </Button>}
        </div>
      );
    };
