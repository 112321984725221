import React from 'react';
import { MYOBLogo, Spinner } from '@myob/myob-widgets';
import { Main as GenericMain } from 'components/generic';
import { ANALYTICS_ID } from '../../constants';
import ProgressButton from './progressButton/ProgressButton';
import config from '../../Config';

interface ConfirmationProps {
  isLoading: boolean,
  stepper?: React.ReactElement,
  pageTitle?: string,
  mainDescription?: React.ReactElement,
  terms?: React.ReactElement
}

const Confirmation: React.FC<ConfirmationProps> = ({
  stepper, isLoading, pageTitle, mainDescription, terms
}) => {
  const content = (
    <div className='feature-box-right'>
      {stepper}
      <div style={{ width: '6.25em', marginBottom: '1em' }}>
        <MYOBLogo/>
      </div>
      {isLoading ?
        <Spinner size='small' data-testid='spinner'/> :
        <div className='confirmation-page-container'>
          <h1 className='confirmation-page-title'>{pageTitle}</h1>
          <div className='confirmation-page-main'>
            {mainDescription}
          </div>
          <ProgressButton
            id={ANALYTICS_ID.BUTTON_ACCESS_SOFTWARE}
            startText='Setting up your software'
            endText='Access your software now'
            duration={config.DEEP_LINKING.BUTTON_DISABLE_TIME}
            href={config.PRODUCT_URL}
          />
          {terms}
        </div>
      }
    </div>
  );

  return (
    <GenericMain>
      {content}
    </GenericMain>
  );
};

export default Confirmation;
