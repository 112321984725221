import _ from 'lodash';
import { PAYROLL_PRICE_PLACEHOLDER } from '../../constants';

const soloFeatures = [
  'Snap and track expenses',
  'Create and send unlimited invoices',
  'Enable in-person payments with Tap to Pay',
  'Track personal and business finances',
  'Automated tax and GST tracking'
];

const productFeatureMapping = [
  {
    productId: '50',
    features: [
      'Unlimited invoices on a professionally designed template',
      'Scan and store receipts with your phone',
      'Manage bills and expenses',
      'Track GST and e-lodge BAS',
      'Payroll for 1 employee'
    ]
  },
  {
    productId: '51',
    features: [
      'Unlimited invoices on a professionally designed template',
      'Scan and store receipts with your phone',
      'Manage bills and expenses',
      'Track GST and e-lodge BAS'
    ]
  },
  {
    productId: '52',
    features: [
      'Unlimited invoices on a professionally designed template',
      'Scan and store receipts with your phone',
      'Manage bills and expenses',
      'Track GST and e-lodge BAS',
      'Payroll for an unlimited number of employees'
    ]
  },
  {
    productId: '53',
    features: [
      'Unlimited invoices on a professionally designed template',
      'Scan and store receipts with your phone',
      'Manage bills and expenses',
      'Track GST and e-lodge BAS',
      'Payroll for an unlimited number of employees'
    ]
  },
  {
    productId: '54',
    features: [
      'Payroll for up to 4 employees per month',
      'Single Touch Payroll (STP) ready',
      'Automated superannuation calculations',
      'Onboard new employees remotely',
      'Employees can access payslips via the MYOB Team app'
    ]
  },
  {
    productId: '58',
    features: [
      'Up to 5 invoices on a professionally designed template',
      'Scan and store receipts with your phone',
      'Manage bills and expenses',
      'Track GST and e-lodge BAS',
      'Payroll for 1 employee'
    ]
  },
  {
    productId: '59',
    features: [
      'Up to 5 invoices on a professionally designed template',
      'Scan and store receipts with your phone',
      'Manage bills and expenses',
      'Track GST and e-lodge BAS'
    ]
  },
  {
    productId: '70',
    features: [
      'Connect unlimited bank and credit card accounts',
      'Calculate and track GST',
      'Advanced reporting and analytics',
      'Upload documents and save them against transactions'
    ]
  },
  {
    productId: '72',
    features: [
      'Connect unlimited bank and credit card accounts',
      'Calculate and track GST',
      'Upload documents and save them against transactions',
      'Payroll for up to 4 employees per month',
      'Automatically calculate tax, Kiwisaver and leave',
      'Automate Payday filing to the IRD',
      'Easily manage leave entitlements'
    ]
  },
  {
    productId: '74',
    features: [
      'Automatically calculate tax, Kiwisaver and leave',
      'Store leave balances',
      'Record pay runs and send payslips',
      'Automate Payday filing to the IRD',
      'Easily manage leave entitlements',
      'Payroll reporting'
    ]
  },
  {
    productId: '75',
    features: [
      'Payroll for up to 4 employees per month',
      'Single Touch Payroll (STP) ready',
      'Automated superannuation calculations',
      'Onboard new employees remotely',
      'Employees can access payslips via the MYOB Team app'
    ]
  },
  {
    productId: '76',
    features: [
      'Connect 2 bank and credit card accounts',
      'Track income and expenses',
      'Unlimited invoices on a professionally designed template',
      'Scan and store receipts with your phone',
      'Calculate and track GST',
      'Tax and basic management reports',
      `Flexible payroll for up to two employees (+$${PAYROLL_PRICE_PLACEHOLDER} per employee per month + GST)`
    ]
  },
  {
    productId: '77',
    features: [
      'Unlimited invoices on a professionally designed template',
      'Scan and store receipts with your phone',
      'Manage bills and expenses',
      'Track GST and e-lodge BAS',
      `Flexible payroll for up to two employees (+$${PAYROLL_PRICE_PLACEHOLDER}/month inc. GST per employee)`
    ]
  },
  {
    productId: '78',
    features: [
      'Connect unlimited bank and credit card accounts',
      'Track income and expenses',
      'Unlimited invoices on a professionally designed template',
      'Scan and store receipts with your phone',
      'Calculate and track GST',
      'Advanced reporting and analytics',
      `Flexible payroll (+$${PAYROLL_PRICE_PLACEHOLDER} per employee per month + GST)`
    ]
  },
  {
    productId: '79',
    features: [
      'Connect unlimited bank and credit card accounts',
      'Unlimited invoices on a professionally designed template',
      'Track GST and e-lodge BAS',
      'Advanced reporting and analytics',
      `Flexible payroll (+$${PAYROLL_PRICE_PLACEHOLDER}/month inc. GST per employee)`
    ]
  },
  {
    productId: '84',
    features: soloFeatures
  },
  {
    productId: '85',
    features: soloFeatures
  }
];

export const productTrailFeaturesNZ = [
  'Access to all features in 30-day FREE trial',
  'Freedom to change your plan at any time',
  'Expert support available 7 days a week',
  'Automated payroll and KiwiSaver'
];

const descForFreePeriod = (trialDurationInMonth: number): string =>
  (trialDurationInMonth === 1 ? '30 days' : `${trialDurationInMonth} months`);

export const productTrailFeaturesAU = (trialDurationInMonth: number): string[] => [
  `${descForFreePeriod(trialDurationInMonth)} of access to ALL features`,
  'Change your plan any time',
  'Speak to real support teams 7 days',
  'Single Touch Payroll'
];

export const getProductFeatureList = (productId: string): string[] => {
  const productFeature = _.find(productFeatureMapping, ['productId', productId]);
  return productFeature ? productFeature.features : new Array<string>();
};

export const getProductFeatureListWithPayrollPrice =
    (featureList: string[], payrollPrice: string): string[] =>
      featureList.map((feature) => feature.replace(`${PAYROLL_PRICE_PLACEHOLDER}`, payrollPrice));
