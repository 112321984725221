export const ELEMENTS = {
  SUBSCRIPTION_START_MESSAGE: Symbol('subscription-start-message'),
  FREE_TRIAL_MESSAGE: Symbol('free-trial-message'),
  EMAIL_PAGE_HEADING_ONE: Symbol('email-page-heading-one'),
  EMAIL_PAGE_HEADING_TWO: Symbol('email-page-heading-two'),
  EMAIL_SUBMIT_BUTTON: Symbol('email-submit'),
  FLOW_STEP_ONE: Symbol('flow-step-one'),
  FLOW_STEP_TWO: Symbol('flow-step-two'),
  FLOW_STEP_THREE: Symbol('flow-step-three'),
  ACCOUNT_DETAILS_INPUT_GIVEN_NAME: Symbol('account-details-input-given-name'),
  ACCOUNT_DETAILS_INPUT_FAMILY_NAME: Symbol('account-details-input-family-name'),
  ACCOUNT_DETAILS_INPUT_PHONE: Symbol('account-details-input-phone'),
  PAYMENT_FORM_SUBMIT_BUTTON: Symbol('payment-form-submit-button'),
  CONFIRMATION_PAGE_TITLE: Symbol('confirmation-page-title')

};
