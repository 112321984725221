import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';

import { useAuth0 } from 'hooks/Auth0/Auth0';

import '../Confirmation.scss';
import { useSendPurchaseEventAndOrderCompletedEvent, useContentViewed } from 'hooks/Analytics/useAnalytics';
import { useEnhancedPurchaseEvent, usePurchaseEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { usePurchaseEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { useNinetailedCheckoutEvent } from 'hooks/useNinetailedAnalytics/useNinetailedAnalytics';
import { Confirmation as GenericConfirmation } from 'components/generic';
import { useCheckout } from 'hooks/useCheckout/useCheckout';
import { CheckoutProps } from 'hooks/useCheckout/types';
import useFeatureToggle from 'hooks/useFeatureToggle/useFeatureToggle';
import ConfirmationPage from '../ConfirmationPage';
import SubscribeStepper from '../../stepper/Subscribe/SubscribeStepper';
import { TermsConditions } from '../../terms/Terms';
import { useSendEventToSegmentForPurchase } from '../useSendEventToSegmentForPurchase';
import {
  ANALYTICS_ID,
  FEATURE_TOGGLE,
  FEELIX_THEMES,
  GTM_ANALYTICS_CONSTANTS,
  NINETAILED_ANALYTICS_CONSTANTS
} from '../../../constants';

function useTrackSubscribeConfirmationPage(email: string | undefined) {
  useContentViewed({
    elementId: ANALYTICS_ID.CONTENT_VIEWED_SUBSCRIBE_CONFIRMATION
  });
  useSendPurchaseEventAndOrderCompletedEvent();
  usePurchaseEventGA4();
  usePurchaseEvent(GTM_ANALYTICS_CONSTANTS.PURCHASE_SEGMENT);
  useSendEventToSegmentForPurchase();
  useEnhancedPurchaseEvent(email);
  // Ninetailed track event for checkout with payment
  useNinetailedCheckoutEvent(NINETAILED_ANALYTICS_CONSTANTS.PURCHASE);
}

function getDescriptionContent(isAnnualPrice: boolean) {
  return isAnnualPrice ? {
    header: 'The first payment may be invoiced on a pro rata basis depending on when you sign up. ' +
        'Payments will be debited from your nominated bank account or credit card.',
    body: 'Your subscription will auto-renew until it is cancelled. ' +
        'You can cancel by visiting the \'Manage My Product\' section of \'My Account\'.',
    footer: 'For further billing or account support, visit ',
    link: 'https://www.myob.com/au/support'
  } : {
    header: 'The first payment may be invoiced on a pro rata basis depending on the day of the month you sign up.',
    body: 'MYOB issues invoices on the 1st of each month and also offers 7 day payment terms, ' +
        'this means that you\'ll receive your invoice on the 1st, and any payment will be debited ' +
        'from your nominated bank account or credit card 7 days later.',
    footer: 'Your subscription auto-renews until it is cancelled. You can cancel at any ' +
        'time within the \'Manage My Product\' section of \'My Account\'.'
  };
}

export const SubscribeConfirmation: React.FC = () => {
  const { user, isLoading: isAuth0Loading, isAuthenticated } = useAuth0();
  const {
    promoCode,
    promotionEndDate,
    isAnnualPrice,
    theme
  } = useSessionContext();
  const location = useLocation<CheckoutProps>();

  const useGeneric = useFeatureToggle(FEATURE_TOGGLE.GENERIC_COMPONENTS_TOGGLE);
  useTrackSubscribeConfirmationPage(user?.email);
  const locationState = location.state;
  const {
    isError,
    isLoading: isCheckoutLoading
  } = useCheckout(locationState);

  const isLoading = isAuth0Loading || isCheckoutLoading;
  if (!isLoading && (!isAuthenticated || isError)) {
    return <Redirect to={'/error'}/>;
  }

  if (theme === FEELIX_THEMES.SOLO || useGeneric) {
    return <GenericConfirmation isLoading={isLoading}/>;
  }

  const description = getDescriptionContent(isAnnualPrice);

  return (
    <ConfirmationPage
      isLoading={isLoading}
      stepper={<SubscribeStepper activeStepNumber='3' />}
      pageTitle='Thank you!'
      mainDescription={
        <div className='confirmation-page-main-text-container'>
          <p>{description.header}</p>
          <p>{description.body}</p>
          <p>
            {description.footer}
            {description.link && <a href={description.link} target='_blank' rel='noopener noreferrer'>
              {description.link}</a>}
          </p>

        </div>
      }
      terms={promoCode && promotionEndDate ? <TermsConditions className='terms'/> : undefined }
    />
  );
};
