import React from 'react';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import style from './RecaptchaDescription.module.scss';
import { FLOW_STEP_MAPPING } from '../../constants';

const recaptchaLink = (content: string, href: string) => (
  <a
    target='_blank'
    rel='noopener noreferrer'
    href={href}
    className={style.recaptchaLink}
  >
    {content}
  </a>);

export const RecaptchaDescription: React.FC = () => {
  const { flowStep, subscriptionType } = useSessionContext();
  const totalSteps = FLOW_STEP_MAPPING[subscriptionType]?.length ?? -1;
  // don't show on google re-captcha branding on last confirmation step
  // flowStep is 0 based
  if (flowStep === totalSteps - 1) return null;
  return (
    <div className={style.recaptchaDescription} data-testid='recaptcha-description'>
      <span>
        {'Invisible reCAPTCHA by Google'}
        {' '}
        {recaptchaLink('Privacy Policy', 'https://policies.google.com/privacy')}
        {' '}and{' '}
        {recaptchaLink('Terms of Use', 'https://policies.google.com/terms')}
      </span>
    </div>
  );
};
