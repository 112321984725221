import React from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Spinner } from '@myob/myob-widgets';
import { Accordion } from 'components/accordion/Accordion';
import { useProductData } from 'hooks/useProductData/useProductData';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { FEELIX_THEMES } from '../../../constants';
import { ProductHeading, PRODUCT_CARD_HEADING } from './productHeader/ProductHeading';
import { ProductFooter } from './productFooter/ProductFooter';
import defaultStyle from './ProductCard.module.scss';
import modernStyle from './ProductCard.solo.module.scss';
import { getStyleProps } from './ProductCard.style';

type ProductCardProps = {
  theme: string;
  isFooterOpen: boolean;
  onFooterOpen: (open: boolean) => void;
}

export const ProductCard: React.FC<ProductCardProps> = (
  {
    theme,
    isFooterOpen,
    onFooterOpen
  }
) => {
  const {
    productId,
    isError,
    isLoading
  } = useProductData();
  const { products } = useContentContext();
  const productDisplayName = products?.find(
    (it) => it.productId === Number(productId)
  )?.fullName || '';

  if (isLoading) return <Spinner size='large'/>;
  if (isError) return <Redirect to='/error' />;
  return (
    <Box
      className={[
        defaultStyle.productCard,
        { [modernStyle.productCard]: theme === FEELIX_THEMES.MODERN }
      ]}
      {...getStyleProps(theme)}
    >
      <Accordion
        title={PRODUCT_CARD_HEADING}
        isOpen={isFooterOpen}
        className={defaultStyle.accordionContent}
        containerClassName={defaultStyle.accordionContainer}
        onClick={(e: any, show: boolean) => onFooterOpen(show)}
        header={<ProductHeading
          heading={productDisplayName}
          isFooterOpen={isFooterOpen}
          theme={theme}
        />}
      >
        <ProductFooter
          theme={theme}
        />
      </Accordion>
    </Box>
  );
};
