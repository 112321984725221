import React from 'react';
import { Box, Stepper } from '@myob/myob-widgets';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { getElement, ProgressBarProps } from '../types';
import style from './modern-progressbar.module.scss';

export const ModernProgressBar: React.FC<ProgressBarProps> =
  ({
    activeStep,
    totalSteps
  }) => {
    const { getLabels } = useContentContext();
    const getType = (cursor: number) => {
      if (activeStep === totalSteps) return 'complete';
      if (cursor === activeStep) return '';
      if (cursor < activeStep) return 'complete';
      return 'incomplete';
    };

    const steps = Array.from({ length: totalSteps }, (_, i) =>
      ({
        number: (i + 1).toString(),
        title: getLabels(getElement(i + 1)),
        // eslint-disable-next-line no-nested-ternary
        type: getType(i + 1)
      }));
    return (
      <Box
        className={[
          style.progressBar,
          activeStep === totalSteps ? style.complete : ''
        ]}
      >
        <Stepper
          activeStepNumber={`${activeStep}`}
          steps={steps}
        />
      </Box>
    );
  };
