import React from 'react';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { FEELIX_THEMES, FLOW_STEP_MAPPING } from '../../../constants';
import { SoloProgressBar } from './solo/SoloProgressBar';
import { ModernProgressBar } from './modern/ModernProgressBar';

export const ProgressBar: React.FC = () => {
  const { theme, flowStep, subscriptionType } = useSessionContext();
  const totalSteps = FLOW_STEP_MAPPING[subscriptionType].length;
  if (flowStep === -1) return null;
  const Component = theme === FEELIX_THEMES.SOLO ? SoloProgressBar : ModernProgressBar;

  return (
    <Component
      activeStep={flowStep + 1} // flow step is 0 based
      totalSteps={totalSteps}
    />
  );
};
