import React, { useState } from 'react';
import { Box } from '@myob/myob-widgets';
import { RecaptchaDescription } from 'components/recaptchaDescription/RecaptchaDescription';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import useFeatureToggle from 'hooks/useFeatureToggle/useFeatureToggle';
import { InfoPanel } from '../infoPanel/InfoPanel';
import { ContentPanel } from '../contentPanel/ContentPanel';
import style from './Main.module.scss';
import { FEATURE_TOGGLE, FEELIX_THEMES } from '../../../constants';

type MainProps = {
  content?: React.ReactNode;
  showProductCard?: boolean;
}
export const Main: React.FC<MainProps> = ({
  children,
  content,
  showProductCard
}) => {
  const [contentPanelFaded, setContentPanelFaded] = useState(false);
  const { theme } = useSessionContext();
  const isGeneric = useFeatureToggle(FEATURE_TOGGLE.GENERIC_COMPONENTS_TOGGLE) || theme === FEELIX_THEMES.SOLO;
  return (
    <Box className={style.main} data-testid='generic-main'>
      <InfoPanel
        contentPanelFaded={contentPanelFaded}
        setContentPanelFaded={setContentPanelFaded}
        showProductCard={showProductCard}
      />
      { children &&
        <ContentPanel faded={contentPanelFaded}>
          {children}
          { isGeneric && <RecaptchaDescription /> }
        </ContentPanel>
      }
      {content}
    </Box>
  );
};
