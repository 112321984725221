import React, { useEffect, useState } from 'react';
import {
  MYOBLogo,
  RadioButton,
  Spinner,
} from '@myob/myob-widgets';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { Redirect } from 'react-router-dom';
import { TermsConditions } from 'components/terms/Terms';
import useFeatureToggle from 'hooks/useFeatureToggle/useFeatureToggle';
import { useAddPaymentInfoEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { useSendEventToSegment } from 'hooks/useSendEventToSegment/useSendEventToSegment';
import { useGetEcommercePrice } from 'hooks/GTMAnalytics/useGTMAnalyticsHelper';
import { useSegmentContext } from 'contexts/segmentProvider/SegmentProvider';
import { useAddPaymentInfoEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { Main as GenericMain, PaymentForm } from 'components/generic';
import { RecaptchaDescription } from 'components/recaptchaDescription/RecaptchaDescription';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useRecaptcha } from 'hooks/useRecaptcha/useRecaptcha';
import { DirectDebitForm } from './directDebitForm/directDebitForm';
import { CreditCardForm } from './credtiCardForm/creditCardForm';
import { CreditCardDetail, DirectDebitDetail } from './types';
import {
  COOKIE_AJS_ANONYMOUS_ID,
  COOKIE_AJS_USER_ID,
  COOKIE_MYOB_VISITOR_ID,
  FEELIX_THEMES,
  GTM_ANALYTICS_CONSTANTS,
  PAYMENT_METHOD,
  PROMOTION_CODE,
  STEP_PATH,
  STEPPER_TYPE,
  FEATURE_TOGGLE
} from '../../constants';
import Steppers from '../stepper/Steppers';
import '../../app/App.scss';
import './PaymentPage.scss';

export const PaymentPage: React.FC = () => {
  const {
    region,
    promoCode,
    promotionEndDate,
    pCustomerId
  } = useSessionContext();

  const { isLoading: isAuth0Loading, isAuthenticated } = useAuth0();

  const initialCardDetail = {
    cardType: '',
    cvv: '',
    expiryMonth: '',
    expiryYear: '',
    maskedNumber: '',
    nameOnCard: '',
    number: ''
  };

  const [creditCardDetail, setCreditCardDetail] = useState<CreditCardDetail>(initialCardDetail);

  const initialDirectDebitDetail: DirectDebitDetail = {
    accountName: '',
    authorised: false,
    bsb: '',
    accountNumber: '',
    bank: '',
    branch: '',
    account: '',
    suffix: ''
  };
  const [directDebitDetail, setDirectDebitDetail] = useState(initialDirectDebitDetail);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CREDIT);

  const history = useHistory();
  const {
    hasPurchaseSubmitted,
    setPaymentMethod: setPaymentMethodInContext,
    isAnnualPrice,
    isAnnualRoute,
    theme
  } = useSessionContext();
  const isSegmentLibraryReady = useSegmentContext();
  const { currency, totalPrice } = useGetEcommercePrice();

  const myobVisitorId = Cookies.get(COOKIE_MYOB_VISITOR_ID);
  const anonymousId = Cookies.get(COOKIE_AJS_ANONYMOUS_ID);
  const userId = Cookies.get(COOKIE_AJS_USER_ID);
  const [submitting, setSubmitting] = useState(false);
  const { success, isLoading } = useRecaptcha({
    enabled: submitting,
    action: 'submit',
    identityId: ''
  });

  const doCheckout = () => {
    const confirmationPath = `/${region.toLowerCase()}` +
      `${isAnnualRoute ? STEP_PATH.SUBSCRIBE.CONFIRMATION_ANNUAL : STEP_PATH.SUBSCRIBE.CONFIRMATION}`;

    history.push(confirmationPath, {
      pCustomerId: promoCode === PROMOTION_CODE.CODE_WESTPACBUSINESSACC_12MTH_FREE ? pCustomerId : null,
      paymentProfile: {
        creditCardDetail,
        directDebitDetail,
        paymentMethod,
        isAnnualPrice
      }
    });
  };
  useEffect(() => {
    if (success) {
      doCheckout();
    }
  }, [success]);
  const trackParams = {
    event: GTM_ANALYTICS_CONSTANTS.ADD_PAYMENT_INFO_SEGMENT,
    userId,
    anonymousId,
    properties: {
      currency,
      value: totalPrice,
      coupon: promoCode,
      myobVisitorId
    }
  };
  useAddPaymentInfoEventGA4(isSegmentLibraryReady);
  useAddPaymentInfoEvent(GTM_ANALYTICS_CONSTANTS.ADD_PAYMENT_INFO_SEGMENT);
  useSendEventToSegment({
    shouldSendSegmentEvent: !!currency && isSegmentLibraryReady,
    trackParams
  });
  const useGeneric = useFeatureToggle(FEATURE_TOGGLE.GENERIC_COMPONENTS_TOGGLE);
  if (hasPurchaseSubmitted || (!isLoading && success === false)) {
    return <Redirect to={'/error'}/>;
  }

  const handleSubmit = () => setSubmitting(true);

  const stepper = <Steppers activeStepNumber='2' firstType='complete' secondType='' stepperType={STEPPER_TYPE.PURCHASE}
    thirdType='incomplete' data-testid='step2'
  />;
  if (isAuth0Loading) {
    return (
      <GenericMain>
        <Spinner/>
      </GenericMain>
    );
  }

  if (!isAuthenticated) {
    return <Redirect to='/error' />;
  }

  const handlePaymentOptionChange = (paymentMethodOption: PAYMENT_METHOD) => {
    setPaymentMethod(paymentMethodOption);
    setPaymentMethodInContext(paymentMethodOption);
  };
  const renderRadioButtonForOption = (paymentMethodOption: PAYMENT_METHOD) => {
    const label = paymentMethodOption === PAYMENT_METHOD.CREDIT ? 'Credit Card' : 'Direct Debit';
    return <RadioButton name='paymentMethod' checked={paymentMethod === paymentMethodOption}
      value={paymentMethodOption} label={label}
      onChange={handlePaymentOptionChange.bind(null, paymentMethodOption)}
    />;
  };
  const renderPaymentForm = () => (paymentMethod === PAYMENT_METHOD.CREDIT ?
    <CreditCardForm
      isPaymentProfileCreationLoading={isLoading}
      setCreditCardDetail={setCreditCardDetail}
      createPaymentProfile={handleSubmit}
    /> :
    <DirectDebitForm
      isPaymentProfileCreationLoading={isLoading}
      setDirectDebitDetail={setDirectDebitDetail}
      createPaymentProfile={handleSubmit}
      promoCode={promoCode}
    />);

  const renderRadioButtons = () => (
    <div className={'horizontal-radio'}>
      {renderRadioButtonForOption(PAYMENT_METHOD.CREDIT)}
      &nbsp;&nbsp;
      {renderRadioButtonForOption(PAYMENT_METHOD.DEBIT)}
    </div>
  );

  if (theme === FEELIX_THEMES.SOLO || useGeneric) {
    return (
      <GenericMain>
        <PaymentForm
          paymentMethod={paymentMethod}
          handlePaymentOptionChange={handlePaymentOptionChange}
        >
          {renderPaymentForm()}
        </PaymentForm>
      </GenericMain>
    );
  }

  return (
    <GenericMain>
      <div className='feature-box-right'>
        {stepper}
        <div style={{ width: '6.25em', marginBottom: '1em' }}>
          <MYOBLogo/>
        </div>
        {renderRadioButtons()}
        {renderPaymentForm()}
        {promoCode && promotionEndDate && <TermsConditions className='terms'/>}
        <RecaptchaDescription/>
      </div>
    </GenericMain>
  );
};
