import React from 'react';
import { get } from 'lodash';
import { Box, Separator, Text } from '@myob/myob-widgets';
import { getTooltip } from 'utils/toolTipUtils';
import isNzPPLProduct from 'utils/IsNzPPLProduct';
import {
  getItemPrice,
  getProductTotalPrice,
  formatToTwoDecimalPlaces,
} from 'utils/pricingUtils';
import { ProductData } from 'hooks/useGetProduct/types';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';

type ModulePriceProps = {
  productData: ProductData;
  moduleProductData: ProductData;
}

const getModuleProductPriceText = (gstDescription: string, moduleProductData: ProductData, productId: string) => {
  if (!moduleProductData?.items) {
    return '';
  }

  const moduleProductPrice = formatToTwoDecimalPlaces(
    getItemPrice(moduleProductData.items[0], isNzPPLProduct(productId))
  );

  return `+ $${moduleProductPrice}/month ${gstDescription} per employee paid`;
};

export const ModulePrice: React.FC<ModulePriceProps> =
  ({
    productData,
    moduleProductData
  }) => {
    const productItems = get(productData, 'items');
    const { productId } = productItems[0];
    const { isAnnualPrice, isAnnualRoute } = useSessionContext();

    const gstDescription = isNzPPLProduct(productId) ? '+ GST' : 'inc. GST';
    const gstDescriptionForToolTip = isNzPPLProduct(productId) ? '(+ GST)' : '(inc. GST)';
    const modulePriceText = getModuleProductPriceText(gstDescription, moduleProductData, productId);

    const minTotalPrice = getProductTotalPrice(productItems, isAnnualRoute);
    if (!modulePriceText) {
      return null;
    }
    return (
      <>
        <div data-testid='min-monthly-total'>
          <Separator
            data-testid='total-price-separator'
            marginTop='sm'
            marginBottom='sm'
          />
          <Box
            display='flex'
            justifyContent='space-between'
            flexDirection='row'
          >
            <Box
              display='flex'
              alignItems='end'
            >
              <Text fontWeight='bold'
                fontSize='sm'
                marginBottom='clear'
              >
                Min. {isAnnualPrice ? 'Yearly' : 'Monthly'} total
              </Text>
              <Text
                fontSize='xs'
                marginBottom='clear'
              >&nbsp;(inc. GST)</Text>
            </Box>
            <Text marginBottom='clear'
              tone='neutralStrongest'
            > ${minTotalPrice} {productItems[0].currency}</Text>
          </Box>
        </div>
        <div data-testid='module-price-text'>
          <Separator
            marginTop='sm'
            marginBottom='sm'
          />
          <div style={{ display: 'flex' }}>
            <Text
              tone='neutralStrongest'
              marginBottom='clear'
              fontSize='xs'
            >{modulePriceText}&nbsp;</Text>
            {getTooltip(
              productItems[0],
              getModuleProductPriceText(gstDescriptionForToolTip, moduleProductData, productId)
            )}
          </div>
        </div>
      </>
    );
  };
