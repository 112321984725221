import React from 'react';
import { Box, MYOBLogo as FeelixMYOBLogo } from '@myob/myob-widgets';
import { FEELIX_THEMES } from '../../constants';
import style from './logo.module.scss';

interface MYOBLogoProps {
  theme: string;
}
export const MYOBLogo: React.FC<MYOBLogoProps> =
  ({
    theme
  }) => {
    if (theme !== FEELIX_THEMES.MODERN) {
      return null;
    }

    return (
      <Box className={style.container}>
        <FeelixMYOBLogo/>
      </Box>
    );
  };
