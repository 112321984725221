import { useQuery } from 'react-query';
import { ProductData, UseGetProductDataType } from '../useGetProduct/types';
import config from '../../Config';
import axios from '../../helpers/axios/axios';
import { useAuth0 } from '../Auth0/Auth0';

export const useGetProductData = (
  productId: string,
  isAnnualRoute: boolean,
  promoCode?: string | undefined
): UseGetProductDataType => {
  const { isLoading: isAuth0Loading } = useAuth0();
  let presentmentUrl = `${config.API_PRODUCT_PRICE_ENDPOINT}?productId=${productId}`;
  if (promoCode) {
    presentmentUrl += `&promoCode=${promoCode}`;
  }
  presentmentUrl += isAnnualRoute ? '&paymentSchedule=ANNUAL' : '';

  const {
    isLoading: isProductDataLoading, isError, data, isSuccess
  } = useQuery(
    ['productData', productId, promoCode],
    () => axios.get(presentmentUrl),
    {
      enabled:
        !isAuth0Loading &&
        Boolean(productId),
      retry: false
    }
  );

  const isLoading = isProductDataLoading || isAuth0Loading;
  const productData = data?.data || {} as ProductData;
  return {
    productData, isLoading, isError, isSuccess
  };
};
